<template>
  <div class="app-box">
    <div class="search-box">
      <input type="text" :placeholder="placeholder" class="search-txt" v-model="keyWord" @input="inputEvent">
      <button class="cancel-btn" @click="$router.back();">取消</button>
    </div>
    <x-scroll-view :top="0.52" bg="#F6F8FB">
      <ul v-if="xhrData.length>0&&type=='brand'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemEvent(item);">{{item.brandName}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='customer'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemCustomerEvent(item);">
          <div class="c-box">
            <span>{{item.orgName}}</span>
            <span>维护人：{{item.maintainer}}</span>
          </div>
        </li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='visitAdd'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemvisitAddEvent(item);">
          <span>{{item.orgName}}</span>
          <i>{{item.customerType==1000?"机构":"经销商"}}</i>
        </li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='drugs'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="drugs-list-item" @click="itemEvent(item);">
          <p class="drugs-title">{{item.medicineName}}</p>
          <p class="drugs-txt m-t-b-10">{{item.specification}}</p>
          <p class="drugs-txt">{{item.manufacturer}}</p>
        </li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='staffInfo'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemStaffInfoEvent(item);">{{item.name}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='pick'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemPickEvent(item);">{{item.name}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='hardwareInfo'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemHardwareInfoEvent(item);">{{item.brandName}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='drugsInfo'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemDrugsInfoEvent(item);">{{item.medicineName}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='university'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemUniversityEvent(item);">{{item.title}}</li>
      </ul>
      <ul v-if="xhrData.length>0&&type=='customerAssignment'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="itemCustomerAssignmentEvent(item);">{{item.name}}</li>
      </ul>
      <div v-if="xhrData.length<=0&&!keyWord&&type=='assignment'" class="list-index-box">
        <x-list-index isAssignment @itemClick="assignmentClick"></x-list-index>
      </div>
      <ul v-if="xhrData.length>0&&!!keyWord&&type=='assignment'" class="search-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="search-list-item" @click="assignmentClick(item);">{{item.name}}</li>
      </ul>
      <x-list-item v-if="xhrData.length>0&&type=='visit'" :xhrData="xhrData" @itemClick="itemVisitClick"></x-list-item>
      <x-no-data v-if="ajaxEnd&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0" :tip="tip"></x-no-data-bg>
      <i v-show="isAddShow&&ajaxEnd&&xhrData.length<=0&&!isLeader" class="add-icon" @click="addEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import listIndex from "@/components/listIndex";
import { listHardwareBrand, searchHardwareBrand, listMedicineBrand, searchMedicineBrand, searchCustomer, queryMyCustomerList, searchStaff, searchHardware, searchMedicine, searchTrainingMaterials } from "@/api/searchPage";
import { querySubordinateList } from "@/api/listIndex";
import { queryList } from "@/api/visitRecord";
import listItem from "@/components/visitRecord/listItem";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      tip: null,
      isAddShow: false,
      customerType: this.$route.params.customerType,
      type: this.$route.params.type,
      keyWord: null,
      ajaxEnd: false,
      placeholder: null,
      timer: null,
      xhrData: [],
      vuexKey: this.$route.params.vuexKey || "setSearchValue"
    };
  },
  created () {
    this.getData();
  },
  methods: {
    addEvent () {
      switch (this.type) {
        case "customer":
          this.$router.push({
            path: `/customerCenter/baseInfo/edit/new/${this.customerType}`
          });
          break;
        case "staffInfo":
          this.$router.push({
            path: `/customerCenter/staffInfo/edit/new/${this.customerType}/${this.$route.params.customerId}`
          });
          break;
        case "hardwareInfo":
          this.$router.push({
            path: `/customerCenter/hardwareInfo/edit/new/${this.$route.params.customerId}/${this.$route.params.hardwareType}/${this.customerType}`
          });
          break;
        case "drugsInfo":
          this.$router.push({
            path: `/customerCenter/drugsInfo/edit/new/${this.customerType}/${this.$route.params.customerId}`
          });
          break;
        default:
          break;
      }
    },
    itemVisitClick (item) {
      this.$router.push({
        path: `/visitRecord/detail/${item.id}`
      });
    },
    itemUniversityEvent (item) {
      this.$router.push({
        path: `/university/detail/${item.id}`
      });
    },
    assignmentClick (item) {
      this.$store.commit(this.vuexKey, item);
      this.$router.back();
    },
    itemEvent (item) {
      this.$store.commit(this.vuexKey, item);
      this.$router.back();
    },
    itemCustomerEvent (item) {
      this.$router.push({
        path: `/customerCenter/detail/${item.id}/${item.customerType}`
      });
    },
    itemvisitAddEvent (item) {
      this.$router.push({
        path: `/visitRecord/edit/new/${item.id}`
      });
    },
    itemStaffInfoEvent (item) {
      this.$router.push({
        path: `/customerCenter/staffInfo/read/${this.customerType}/${item.id}`
      });
    },
    itemPickEvent (item) {
      this.$store.commit("setSearchValue", item);
      this.$router.go(-2);
    },
    itemHardwareInfoEvent (item) {
      this.$router.push({
        path: `/customerCenter/hardwareInfo/read/${item.id}/${this.$route.params.hardwareType}/${this.customerType}`
      });
    },
    itemCustomerAssignmentEvent (item) {
      this.$router.push({
        path: `/customerAssignment/resourceList/${item.id}`
      });
    },
    itemDrugsInfoEvent (item) {
      this.$router.push({
        path: `/customerCenter/drugsInfo/read/${this.customerType}/${item.id}`
      });
    },
    getData () {
      switch (this.type) {
        case "brand":
          this.placeholder = "请输入硬件品牌";
          this.getBrandData();
          break;
        case "drugs":
          this.placeholder = "请输入药品名称或通用名";
          this.getMedicineBrandData();
          break;
        case "customer":
          this.isAddShow = true;
          !this.isLeader && (this.tip = "还没有任何内容，快新建一条吧~");
          this.placeholder = "请输入机构或经销商名称";
          break;
        case "visitAdd":
          this.placeholder = "请输入机构或经销商名称";
          this.getMyCustomerList();
          break;
        case "pick":
          this.placeholder = "请输入联系人姓名";
          break;
        case "hardwareInfo":
          this.isAddShow = true;
          !this.isLeader && (this.tip = "还没有任何内容，快新建一条吧~");
          this.placeholder = "请输入硬件品牌";
          break;
        case "drugsInfo":
          this.isAddShow = true;
          !this.isLeader && (this.tip = "还没有任何内容，快新建一条吧~");
          this.placeholder = "请输入药品名称或通用名";
          break;
        case "university":
          this.placeholder = "请输入培训资料标题";
          break;
        case "staffInfo":
          this.isAddShow = true;
          !this.isLeader && (this.tip = "还没有任何内容，快新建一条吧~");
          this.placeholder = "请输入员工姓名";
          break;
        case "customerAssignment":
        case "assignment":
          this.placeholder = "请输入员工姓名";
          break;
        case "visit":
          this.placeholder = "请输入机构或经销商名称";
          break;
        default:
          break;
      }
    },
    inputEvent () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.xhrData = [];
        this.ajaxEnd = false;
        switch (this.type) {
          case "brand":
            this.searchBrand();
            break;
          case "drugs":
            this.searchMedicineBrand();
            break;
          case "customer":
            this.searchCustomerData();
            break;
          case "visitAdd":
            this.searchCustomerData(true);
            break;
          case "pick":
          case "staffInfo":
            this.getSearchStaffData();
            break;
          case "hardwareInfo":
            this.getSearchHardware();
            break;
          case "drugsInfo":
            this.getSearchMedicine();
            break;
          case "university":
            this.getSearchTrainingMaterials();
            break;
          case "customerAssignment":
            this.getQuerySubordinateList();
            break;
          case "assignment":
            this.getQuerySubordinateList();
            break;
          case "visit":
            this.getQueryList();
            break;
          default:
            break;
        }
      }, 300);
    },
    getQueryList () {
      if (this.keyWord) {
        queryList({
          keyWord: this.keyWord,
          pageNum: 1,
          pageSize: 100
        }).then(xhr => {
          this.xhrData = xhr.data.records;
          this.ajaxEnd = true;
        });
      }
    },
    getQuerySubordinateList () {
      if (this.keyWord) {
        querySubordinateList({
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      }
    },
    getSearchTrainingMaterials () {
      if (this.keyWord) {
        searchTrainingMaterials({
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      }
    },
    getSearchMedicine () {
      if (this.keyWord) {
        searchMedicine({
          customerId: this.$route.params.customerId,
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      }
    },
    getSearchHardware () {
      if (this.keyWord) {
        searchHardware({
          customerId: this.$route.params.customerId,
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      }
    },
    getSearchStaffData () {
      if (this.keyWord) {
        searchStaff({
          customerId: this.$route.params.customerId,
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      }
    },
    getMyCustomerList () {
      queryMyCustomerList().then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    getBrandData () {
      listHardwareBrand().then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    searchBrand () {
      if (this.keyWord) {
        searchHardwareBrand({
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      } else {
        this.getBrandData();
      }
    },
    getMedicineBrandData () {
      listMedicineBrand().then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    searchMedicineBrand () {
      if (this.keyWord) {
        searchMedicineBrand({
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      } else {
        this.getMedicineBrandData();
      }
    },
    searchCustomerData (isReset = false) {
      if (this.keyWord) {
        searchCustomer({
          keyWord: this.keyWord
        }).then(xhr => {
          this.xhrData = xhr.data;
          this.ajaxEnd = true;
        });
      } else {
        if (isReset) {
          this.getMyCustomerList();
        }
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noData.name]: noData,
    [listIndex.name]: listIndex,
    [noDataBg.name]: noDataBg,
    [listItem.name]: listItem
  }
};
</script>

<style scoped>
.search-list-box {
  background-color: #ffffff;
  border-radius: 0.08rem;
  width: 3.45rem;
  margin: 0.1rem auto 0;
}
.search-list-item {
  display: flex;
  justify-content: space-between;
  width: 3.2rem;
  margin: auto;
  padding: 0.2rem 0;
  line-height: 1;
  font-size: 0.15rem;
  color: #30353d;
  border-bottom: solid 0.01rem #f6f8fb;
}
.search-list-box .search-list-item:last-of-type {
  border-bottom: 0;
}
.search-list-item i {
  display: inline-table;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  font-size: 12px;
  color: #2b82f7;
  padding: 0.02rem 0.06rem;
}
.drugs-list-item {
  margin: auto;
  width: 3.21rem;
  padding: 0.15rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
  line-height: 1;
}
.drugs-title {
  font-size: 0.15rem;
  color: #30353d;
}
.drugs-txt {
  font-size: 0.13rem;
  color: #93969d;
}
.m-t-b-10 {
  margin: 0.1rem auto;
}
.list-index-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f8fb;
}
.c-box {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.c-box span:first-of-type {
  margin-bottom: 0.05rem;
}
.c-box span:last-of-type {
  margin-top: 0.05rem;
}
</style>
